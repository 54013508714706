export default {
  "All Right Reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved"])},
  "Forgot Password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password ?"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "Sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection"])},
  "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "The string should have a minimum length of n characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The string must have a minimum length of ", _interpolate(_list(0)), " characters", _interpolate(_list(1))])},
  "The string should have a maximum length of n characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The string must have a maximum length of ", _interpolate(_list(0)), " characters", _interpolate(_list(1))])},
  "The string should have a minimum of n uppercase letters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The string must contain at least ", _interpolate(_list(0)), " uppercase letter", _interpolate(_list(1))])},
  "The string should have a minimum of n lowercase letters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The string must contain at least ", _interpolate(_list(0)), " lowercase letter", _interpolate(_list(1))])},
  "The string should have a minimum of n digits": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The string must have at least ", _interpolate(_list(0)), " digit", _interpolate(_list(1))])},
  "The string should have a minimum of n symbols": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The string must have at least ", _interpolate(_list(0)), " symbol", _interpolate(_list(1))])},
  "Please enter a valid email address and password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address and password."])},
  "Please enter a valid email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address."])},
  "Send code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send code"])},
  "Please enter your email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email address"])},
  "Please enter a valid code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the code received on your email address"])},
  "Enter code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the code"])},
  "Verify code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check code"])},
  "Critical Error: Sign-in failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical error: Authentication failed!"])},
  "Connect with Flash Meeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect with Flash Meeting"])},
  "Confirm Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
  "Change Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change my password"])},
  "Please enter a valid password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid password"])},
  "Passwords do not match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect"])},
  "Oops, this page not found!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, page not found!"])},
  "Go back home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to home page"])},
  "Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "Max size of images and documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max size of images and documents"])},
  "Mo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MB"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
  "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My resolutions"])},
  "Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "Pilote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilot"])},
  "Pilotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drivers"])},
  "Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsible"])},
  "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
  "Last Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Activity"])},
  "Timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time zone"])},
  "RP List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem resolutions list"])},
  "Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display"])},
  "Showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has"])},
  "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])},
  "entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entries"])},
  "Search:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To research :"])},
  "filter of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtered from the total number of "])},
  "OPEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "CLOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farm"])},
  "REOPEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-opened"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
  "reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-opened"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["title"])},
  "punch_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conclusion"])},
  "Closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "Re-open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-open"])},
  "securing_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Securing actions"])},
  "register date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["register date"])},
  "last activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last activity"])},
  "This RP has re-open under the number n": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["This PR has been reopened under number ", _interpolate(_list(0))])},
  "Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
  "created the RP at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["created the PR on"])},
  "close the RP at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["closed the PR on"])},
  "reopen the RP at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["re-opened the PR on"])},
  "under number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["under the number"])},
  "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opened on"])},
  "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated on"])},
  "RP Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PR no."])},
  "history-rp-change-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["changed the title of the PR on"])},
  "history-rp-change-pilote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modified the PR driver on"])},
  "history-rp-add-user": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["added user ", _interpolate(_list(0)), " on"])},
  "history-rp-remove-user": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["deleted user ", _interpolate(_list(0)), " on"])},
  "history-rp-change-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["changed the description of the PR on"])},
  "Site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site"])},
  "Description of problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of the problem"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "Users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
  "Who": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who"])},
  "What": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What"])},
  "Where": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where"])},
  "When": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When"])},
  "How": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How"])},
  "Why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why"])},
  "Time mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time format"])},
  "12h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12h"])},
  "24h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h"])},
  "datetime-picker-select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "datetime-picker-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "Securing Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Securing actions"])},
  "No Available Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available"])},
  "Tools-6W": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QQOQCP"])},
  "Tools-6W-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The QQOQCP method"])},
  "Suggested problem description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proposed description of the problem"])},
  "construct_suggestec_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["On ", _interpolate(_named("whenddate")), " at ", _interpolate(_named("whendtime")), ", ", _interpolate(_named("who")), " reported ", _interpolate(_named("how")), " ", _interpolate(_named("what")), " in ", _interpolate(_named("where")), ". "])},
  "Use description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use description"])},
  "Completed date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Made on"])},
  "Causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probable causes"])},
  "Ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking"])},
  "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "Ishikawa 7M Label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ishikawa 7M labels list"])},
  "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "Lorem ...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum..."])},
  "Lorem ipsum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipiscing elit."])},
  "Due date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "Archive date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive date"])},
  "Archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
  "Archives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archives"])},
  "Unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unarchive"])},
  "Complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
  "Notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
  "Complete to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished on"])},
  "Archive to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived on"])},
  "Board": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control panel"])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "Uncompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uncompleted"])},
  "Archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
  "Drag 'n' drop some files here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag and drop your files here\n"])},
  "Drag 'n' drop some file here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag and drop your file here\n"])},
  "StickyNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import to Ishikawa"])},
  "StickyNotes List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imported images"])},
  "Run": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load..."])},
  "Validate the modification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate changes and return to PR"])},
  "Cancel and return to RP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel and return to PR"])},
  "All fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All columns"])},
  "When detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When"])},
  "When appeared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appeared on"])},
  "Add user in RP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a participant"])},
  "Download Ishikawa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Ishikawa"])},
  "Download report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download report"])},
  "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
  "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "Progress of RP n° ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress of PR n° "])},
  "Pareto diagram of probable causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pareto chart of probable causes"])},
  "Total number of causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of causes"])},
  "Reactivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reactivity"])},
  "First securing action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First securing action"])},
  "No securing action in place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No securing action in place"])},
  "Started date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release date"])},
  "Late Planned actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Late planned actions"])},
  "Late completed actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions carried out late"])},
  "Identified actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions identified"])},
  "Total number of actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of actions"])},
  "Actions distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions distribution"])},
  "Median delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Median delay"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
  "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
  "Percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage"])},
  "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unit"])},
  "days short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["j"])},
  "hours short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h"])},
  "minutes short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
  "RP Pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numbering of resolutions"])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "Action details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details of an action"])},
  "Securing action details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details of a securing action"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "Confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
  "Event details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event details"])},
  "Confirm deletion of an event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation of deletion of an event"])},
  "Do you really want to delete this engagement ? \nIt will close the details window previously opened.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to remove this commitment?\n"])},
  "Confirmation of archiving unfinished engagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uncompleted event archiving confirmation"])},
  "Would you like to archive an engagement that hasn't been completed ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to archive an unfinished event ?"])},
  "Identified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identified"])},
  "Planned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planned"])},
  "Delayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue"])},
  "Undefined category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undefined category"])},
  "Done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "In progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
  "To do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To do"])},
  "Securisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Securization"])},
  "Capitalisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitalization"])},
  "Completes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
  "Securing date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Securing date"])},
  "Detected Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detection date"])},
  "Securing date tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The securing action is in place, the customer is protected\n- The security date is later than the problem detection date\n- At least one impact study linked to the action before being able to set a security date"])},
  "Identified Singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identified"])},
  "Planned Singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planned"])},
  "Securing actions total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Securing actions total"])},
  "Causes total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causes total"])},
  "Actions total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions total"])},
  "Planned actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planned actions"])},
  "Completed actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed actions"])},
  "Effective actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effective actions"])},
  "Documents total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total problem resolution related documents saved on the server"])},
  "Documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
  "Upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "Edit a file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit a file"])},
  "File deletion confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File deletion confirmation"])},
  "Are you sure you want to delete this file ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this file ?"])},
  "Create a new problem resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new problem resolution"])},
  "Add a securing action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a securing action"])},
  "Add an action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an action"])},
  "Add a cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a cause"])},
  "Validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To validate"])},
  "Tools-6W tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QQOQCP is a method that allows you to formalize the description of a problem. "])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "Are you sure you want to close this problem resolution ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to close this problem resolution ?"])},
  "Problem resolution closing confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem resolution closing confirmation"])},
  "New problem resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New problem resolution"])},
  "Notes total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total problem resolution notes"])},
  "Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
  "Add a note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a note"])},
  "Selected action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected action"])},
  "Probable causes list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probable causes list"])},
  "Link action to probable causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link action to probable causes"])},
  "Linked causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked causes"])},
  "Unlinked causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlinked causes"])},
  "All causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All causes"])},
  "Sort & filter options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorting and filtering options"])},
  "All (Default)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All (Default)"])},
  "Register date descending (Default)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register date descending (Default)"])},
  "Register date ascending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register date increasing"])},
  "Last activity descending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last activity decreasing"])},
  "Last activity ascending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last activity ascending"])},
  "Title A-Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title A-Z"])},
  "Title Z-A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title Z-A"])},
  "Description A-Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description A-Z"])},
  "Description Z-A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description Z-A"])},
  "With a pilot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With a pilot"])},
  "Without a pilot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without a pilot"])},
  "With a securing date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With a securing date"])},
  "Without a securing date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without a securing date"])},
  "Ranking ascending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking ascending"])},
  "Ranking descending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking descending"])},
  "Sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
  "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "Efficients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficients"])},
  "Action efficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effective action"])},
  "Efficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficient"])},
  "Type list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typical list"])},
  "Add a capitalising action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a capitalization action"])},
  "Link capitalising action to efficient actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link a capitalization action to effective actions"])},
  "Capitalising actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitalization actions"])},
  "Capitalising actions total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitalization actions total"])},
  "Planned capitalising actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planned capitalization actions"])},
  "Completed capitalising actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed capitalization actions"])},
  "Selected capitalising action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected capitalization action"])},
  "Efficient actions list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effective actions list"])},
  "All actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All actions"])},
  "Linked actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked actions"])},
  "Unlinked actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlinked actions"])},
  "Capitalising actions list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitalising actions list"])},
  "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "Add a template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a template"])},
  "Template title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template title"])},
  "Late days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days late"])},
  "Capitalising action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitalization action"])},
  "Action completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action completed"])},
  "Recurrent problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring problem"])},
  "from x to y days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["from ", _interpolate(_list(0)), " to ", _interpolate(_list(1)), " days"])},
  "more than or equal to x days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["≥ ", _interpolate(_list(0)), " days"])},
  "RP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problems solving"])},
  "Open duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening time"])},
  "Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
  "Pareto Action Title Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pareto of actions by service"])},
  "Duration RP Title Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Resolutions Diagram"])},
  "Late Action Title Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue Actions Diagram"])},
  "Counters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counters"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])},
  "Description PLACEHOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description..."])},
  "Title PLACEHOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title..."])},
  "Add a pilot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a pilot"])},
  "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["others"])},
  "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "Open date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening date"])},
  "Last RP visited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest resolution"])},
  "A.M.D.E.C legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A.M.D.E.C: Analysis of Failure Modes, their Effects and their Criticality"])},
  "Max number of files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max number of files"])},
  "Warning too much file selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention :\n"])},
  "Warning file to big": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Warning: The file '", _interpolate(_named("fileName")), "' is too big"])},
  "RP Number pattern year 4 digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allows you to add the year of creation of the problem resolution on 4 digits."])},
  "RP Number pattern year 2 digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allows you to add the year of creation of the problem resolution on 2 digits."])},
  "RP Number pattern month 2 digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allows you to add the month of creation of the problem resolution on 2 digits."])},
  "RP Number pattern month 1 or 2 digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allows you to add the month the problem resolution was created in 1 or 2 digits."])},
  "RP Number pattern day 2 digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allows you to add the day the problem resolution was created in 2 digits."])},
  "RP Number pattern day 1 or 2 digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allows you to add the day the problem resolution was created in 1 or 2 digits."])},
  "RP Number pattern x digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allows you to convert the problem resolution number with the number of"])},
  "RP Number pattern example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the 12th resolution, opened on July 5, 2023, the numbering."])},
  "Example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example"])},
  "The resolution doesn't exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The resolution does not exist"])},
  "_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])},
  "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["period"])},
  "Late actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Late actions"])},
  "Recurrence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recursion"])},
  "Duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Median duration"])},
  "Resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolution"])},
  "Actions and Capitalising actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions and capitalising actions"])},
  "There are x late actions out of a total of y": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["There are ", _interpolate(_list(0)), " out of ", _interpolate(_list(1)), " overdue actions"])},
  "There are x recurring RP out of a total of y": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["There are ", _interpolate(_list(0)), " recurring resolutions out of ", _interpolate(_list(1)), " in total"])},
  "Median time securring action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The median securing time is ", _interpolate(_list(0))])},
  "Median time progress RP": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The median problem resolution time is ", _interpolate(_list(0))])},
  "Recurrent resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring resolution"])},
  "This week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This week"])},
  "This month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This month"])},
  "This year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This year"])},
  "Tools-Context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Context"])},
  "Tools-Context tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Context will provide additional details."])},
  "Use context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use context"])},
  "Tools-Context question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Context and detailed explanations useful for the analysis."])},
  "Services list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services list"])},
  "Add a service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a service"])},
  "Service name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Name"])},
  "name PLACEHOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name..."])},
  "Add a user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a participant"])},
  "Users_bis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "Users list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users list"])},
  "Firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
  "Lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
  "Management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "Modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify"])},
  "Confirmation of service deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service deletion confirmation"])},
  "Are you sure you want to delete the x service ?": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Are you sure you want to delete service \"", _interpolate(_list(0)), "\"?"])},
  "Users of this service will not be on any service": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Users of this service (", _interpolate(_list(0)), ") will not be found on any service"])},
  "Last year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last year"])},
  "Last month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last month"])},
  "Last week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last week"])},
  "Impact Studies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impact study"])},
  "Answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
  "Question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
  "Link secruring action to impact studies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link securing action to impact studies"])},
  "List of impact studies with a response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of impact studies with a response"])},
  "All impact studies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All impact studies"])},
  "Linked impact studies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked impact studies"])},
  "Unlinked impact studies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlinked impact studies"])},
  "No impact study is associated with the RP.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No impact study is associated with the PR."])},
  "{0} impact study with no response": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " impact study to fill in"])},
  "{0} impact studies with no response": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " impact studies to fill in"])},
  "Search bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search bar"])},
  "Tools-IsIsNot-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is/Isn't"])},
  "Tools-IsIsNot tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identify the elements concerned or not"])},
  "Is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is"])},
  "Is not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is not"])},
  "Is tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is affected by the problem"])},
  "Is not tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is not affected by the problem"])},
  "Empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty"])},
  "Label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
  "Is/Is not tool default list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default criteria list for the Is/Is not tool"])},
  "Is/Is not tool default list tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of criteria created by default in a problem resolution for the Is/Is not tool"])},
  "Section label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criterion label"])},
  "Add a section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a criterion"])},
  "Tool 5 why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 Why"])},
  "Selected node": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected node"])},
  "Add a Why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Why"])},
  "Delete Why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove the Why"])},
  "Create as causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create as causes"])},
  "Integrate in causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrate in causes"])},
  "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "Selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
  "Unselected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deselected"])},
  "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "Lists of why to create to causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lists of whys to create causes"])},
  "Why PLACEHOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label of Why..."])},
  "Tool 5 why tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The 5 why is a method of identifying the root causes of a problem in the form of a cause tree"])},
  "Why deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation of deletion of a Why"])},
  "Why deletion BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting the selected Why will delete its child Whys.<br>So can you confirm the deletion of the Why selected ?"])},
  "Modify a section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit a criterion"])},
  "Is field description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is field description"])},
  "Is not field description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is not field description"])},
  "Particularity field description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particularity field description"])},
  "Change field description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change field description"])},
  "Particularity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particularity"])},
  "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
  "Cause details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause details"])},
  "Fishbone icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fishbone icon"])},
  "Company logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company logo"])},
  "No logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No logo"])},
  "Select a logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a logo"])},
  "Logo deletion HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo removal confirmation"])},
  "Logo deletion BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to remove the logo ?"])},
  "Valid extensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid extensions: .png, .jpeg, .jpg"])},
  "Choose file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose file"])},
  "Reset tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset to default"])},
  "ishikawa_7m HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ishikawa 7M default reset"])},
  "ishikawa_7m BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to reset the labels of the 7M of the Ishikawa diagram to default?<br>This will cancel any changes to them."])},
  "capitalisation_template HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default delivery of capitalization actions"])},
  "capitalisation_template BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to reset capitalization actions to default?<br>This will undo any changes to them and remove any added ones."])},
  "is_is_not_template HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetting the basic criteria of Is/Is not to default"])},
  "is_is_not_template BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to reset the basic Is/Isn't criteria to default?<br>This will undo any changes to them and remove any added ones."])},
  "Not linked causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not linked causes"])},
  "Is Is not row deletion HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row deletion confirmation"])},
  "Is Is not row deletion BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this line?<br>This will delete the line and all content on it."])},
  "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
  "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
  "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
  "Is/Is not criterion deletion confirmation HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criterion deletion confirmation"])},
  "Is/Is not criterion deletion confirmation BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this criterion and all the descriptions associated with it ?"])},
  "Capitalisation template deletion confirmation HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitalization action deletion confirmation"])},
  "Capitalisation template deletion confirmation BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this capitalization action ?"])},
  "Ishikawa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ishikawa"])},
  "Report customization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report customization"])},
  "Sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sections"])},
  "Tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
  "Documents allowed tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only documents with valid extension are displayed"])},
  "Allowed extensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed extensions :"])},
  "Client name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client name"])},
  "Enter a client name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a client name"])},
  "Client number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client number"])},
  "Enter a client number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a client number"])},
  "RP Number pattern site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allows you to add the site of the problem resolution"])},
  "RP Number pattern service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allows you to add the service of the pilote of the problem resolution"])},
  "Copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright © PRODEO"])},
  "Congratulations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations"])},
  "Go to home page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to home page"])},
  "Stay here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay here"])},
  "Tool-priority-matrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority matrix"])},
  "Tool-priority-matrix tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tool that prioritizes probable causes regarding customed criteria."])},
  "Causes weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causes weight"])},
  "Modify a criterion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify a criterion"])},
  "Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
  "Ascending weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascending weight"])},
  "Criterion name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criterion name"])},
  "Criterion description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criterion description"])},
  "Add a criterion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a criterion"])},
  "Classify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classify"])},
  "Criteria templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criteria templates"])},
  "Templates list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates list"])},
  "Priority matrix criteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority matrix criteria"])},
  "Priority matrix criteria tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority matrix criteria templates list"])},
  "priority_matrix_template HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetting priority matrix criteria"])},
  "priority_matrix_template BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to reset the basic criteria of the priority matrix to default ?<br>This will undo any changes made to them and delete any new ones added."])},
  "PR progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PR progress"])},
  "Pareto priority matrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pareto priority matrix"])},
  "Pareto of probable causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pareto of probable causes"])},
  "Indicators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicators"])},
  "Create an RP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an RP"])},
  "Today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "Participants of the problem solving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants of the problem solving"])},
  "Archived actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived actions"])},
  "Ongoing actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing actions"])},
  "My settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My settings"])},
  "Standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
  "Strip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strip"])},
  "Restricted content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restricted content"])},
  "Global settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global settings"])},
  "Select all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
  "Unselect all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unselect all"])},
  "Error message 403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["403 : You do not have the required authorizations"])},
  "Error message 404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 : API route not found"])},
  "Error message 405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["405 : HTTP method not allowed"])},
  "Error message 406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["406 : You are not authenticated"])},
  "Error message 407": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["407 : Your user account is blocked"])},
  "Error message 408": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["408 : Your licence is expired"])},
  "Error message 409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["409 : Your authentication token is invalid"])},
  "RP progressions chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RP progressions chart"])},
  "D1 tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define the problem"])},
  "D2 tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build the team"])},
  "D3 tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implement securing actions"])},
  "D4 tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identify root causes"])},
  "D5 tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identify and plan solutions"])},
  "D6 tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implement and qualify solutions"])},
  "D7 tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up capitalization actions"])},
  "D8 tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All capitalization actions completed\nCongratulations to the team"])},
  "Tool-qrqc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRQC"])},
  "Tool-qrqc tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The QRQC tool (Quick Response Quality Control) is used to methodically identify the causes of problem creation and non-detection"])},
  "Effect of factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effect of factor"])},
  "Effect of factor PLACEHOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effect of factor..."])},
  "Add a QRQC Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a control point"])},
  "Add a QRQC Factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a factor effect"])},
  "Control point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control point"])},
  "Standard PLACEHOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard..."])},
  "OK part measurement PLACEHOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK part measurement..."])},
  "NOK part measurement PLACEHOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOK part measurement..."])},
  "OK part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK part"])},
  "NOK part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOK part"])},
  "Measurement or specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurement or specification"])},
  "Measurement with tolerance ( X +/- ) or technical specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurement with tolerance ( X +/- ) or technical specification"])},
  "Measurement or technical specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurement or technical specification"])},
  "Observation versus standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observation versus standard"])},
  "Does the measurement or technical specification observed on the OK part comply with the standard ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does the measurement or technical specification observed on the OK part comply with the standard ?"])},
  "The standard presents the effect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The standard presents the effect"])},
  "Does a part that conforms to the standard show the effect of the factor ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does a part that conforms to the standard show the effect of the factor ?"])},
  "No validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non valider"])},
  "Non-detection factors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-detection factors"])},
  "Find out why the problem was not detected ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find out why the problem was not detected ?"])},
  "Creation factors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation factors"])},
  "Search for reasons why the problem was generated ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for reasons why the problem was generated ?"])},
  "Are you sure you want to remove the effect ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to remove the effect ?"])},
  "There are {0} control point(s) associated with this effect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["There are ", _interpolate(_list(0)), " control point(s) associated with this effect"])},
  "Control points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control points"])},
  "Validate as a probable cause of the effect \"{0}\"": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Validate this controller point as a probable cause of the \"", _interpolate(_list(0)), "\" effect"])},
  "Integrate retained causes into the resolution process.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrate retained causes into the resolution process."])},
  "{0} potentially leads to the effect of {1} \"{2}\"": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["\"", _interpolate(_list(0)), "\" potentially leads to the effect of ", _interpolate(_list(1)), " \"", _interpolate(_list(2)), "\""])},
  "Integrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrate"])}
}