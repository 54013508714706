export default {
  "All Right Reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous droits réservés"])},
  "Forgot Password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "Sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "The string should have a minimum length of n characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La chaîne doit avoir une longueur minimale de ", _interpolate(_list(0)), " caractère", _interpolate(_list(1))])},
  "The string should have a maximum length of n characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La chaîne doit avoir une longueur maximale de ", _interpolate(_list(0)), " caractères", _interpolate(_list(1))])},
  "The string should have a minimum of n uppercase letters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La chaîne doit comporter au moins ", _interpolate(_list(0)), " lettre majuscule", _interpolate(_list(1))])},
  "The string should have a minimum of n lowercase letters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La chaîne doit comporter au moins ", _interpolate(_list(0)), " lettre minuscule", _interpolate(_list(1))])},
  "The string should have a minimum of n digits": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La chaîne doit comporter au moins ", _interpolate(_list(0)), " chiffre", _interpolate(_list(1))])},
  "The string should have a minimum of n symbols": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La chaîne doit comporter au moins ", _interpolate(_list(0)), " symbole", _interpolate(_list(1))])},
  "Please enter a valid email address and password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir une adresse e-mail et un mot de passe valides."])},
  "Please enter a valid email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir une adresse e-mail valide."])},
  "Send code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer le code"])},
  "Please enter your email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir votre adresse email"])},
  "Please enter a valid code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir le code reçu sur votre adresse mail"])},
  "Enter code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir le code"])},
  "Verify code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifer le code"])},
  "Critical Error: Sign-in failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur critique: L'authentification a échouée !"])},
  "Connect with Flash Meeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter avec Flash Meeting"])},
  "Confirm Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])},
  "Change Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer mon mot de passe"])},
  "Please enter a valid password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un mot de passe valide"])},
  "Passwords do not match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondes pas"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
  "Oops, this page not found!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups, page introuvable !"])},
  "Go back home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à la page d'accueil"])},
  "Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
  "Max size of images and documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille maximum des images et des documents"])},
  "Mo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mo"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
  "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes résolutions"])},
  "Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro"])},
  "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
  "Pilote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilote"])},
  "Pilotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilotes"])},
  "Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable"])},
  "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État"])},
  "Last Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité récente"])},
  "Timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuseau horaire"])},
  "RP List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des résolutions de problèmes"])},
  "Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher"])},
  "Showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affichage"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
  "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur"])},
  "entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entrées"])},
  "Search:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher :"])},
  "filter of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtré du nombre total de "])},
  "OPEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir"])},
  "CLOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermé"])},
  "REOPEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ré-ouvert"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvert"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermé"])},
  "reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ré-ouvert"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["titre"])},
  "punch_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conclusion"])},
  "Closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
  "Re-open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ré-ouvrir"])},
  "securing_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions de sécurisation"])},
  "register date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date d'ouverture :"])},
  "last activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dernière modifications :"])},
  "This RP has re-open under the number n": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Cette RP à été réouverte sous le numéro ", _interpolate(_list(0))])},
  "Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
  "created the RP at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a créé la RP le"])},
  "close the RP at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a fermé la RP le"])},
  "reopen the RP at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a ré-ouvert la RP le"])},
  "under number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sous le numero"])},
  "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouverte le"])},
  "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour le"])},
  "RP Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RP n°"])},
  "history-rp-change-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a changé le titre de la RP le"])},
  "history-rp-change-pilote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a modifié le pilote de la RP le"])},
  "history-rp-add-user": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["a ajouté l'utilisateur ", _interpolate(_list(0)), " le"])},
  "history-rp-remove-user": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["a supprimé l'utilisateur ", _interpolate(_list(0)), " le"])},
  "history-rp-change-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a modifié la description de la RP le"])},
  "Site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site"])},
  "Description of problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du problème"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "Users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
  "Who": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui"])},
  "What": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quoi"])},
  "Where": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où"])},
  "When": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quand"])},
  "How": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "Why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi"])},
  "Time mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format de l'heure"])},
  "12h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12h"])},
  "24h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h"])},
  "datetime-picker-select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "datetime-picker-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "Securing Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions de sécurisation"])},
  "No Available Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée disponible"])},
  "Tools-6W": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QQOQCP"])},
  "Tools-6W-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La méthode QQOQCP"])},
  "Suggested problem description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proposition de description du problème"])},
  "construct_suggestec_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le ", _interpolate(_named("whenddate")), " à ", _interpolate(_named("whendtime")), ", ", _interpolate(_named("who")), " nous a signalé ", _interpolate(_named("how")), " ", _interpolate(_named("what")), " dans ", _interpolate(_named("where")), ".\nCet événement est initialement apparu le ", _interpolate(_named("whenadate")), " à ", _interpolate(_named("whenatime")), "."])},
  "Use description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser la description"])},
  "Completed date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réalisée le"])},
  "Causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causes probables"])},
  "Ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classement"])},
  "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
  "Ishikawa 7M Label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé des 7M de l'ishikawa"])},
  "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "Lorem ...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum ..."])},
  "Lorem ipsum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipiscing elit."])},
  "Due date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date prévue"])},
  "Archive date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'archivage"])},
  "Archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiver"])},
  "Archives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archives"])},
  "Unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désarchiver"])},
  "Complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer"])},
  "Notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
  "Complete to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé le"])},
  "Archive to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivé le"])},
  "Board": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de pilotage"])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
  "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminée"])},
  "Uncompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non terminée"])},
  "Archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivé"])},
  "Drag 'n' drop some files here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glisser-déposer vos fichiers ici \nou\n cliquer ici pour sélectionner des fichiers"])},
  "Drag 'n' drop some file here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glisser-déposer votre fichier ici \nou\n cliquer ici pour sélectionner un fichier"])},
  "StickyNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer sur Ishikawa"])},
  "StickyNotes List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images importées"])},
  "Run": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charger..."])},
  "Validate the modification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider les modifications et retourner à la RP"])},
  "Cancel and return to RP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler et retourner à la RP"])},
  "All fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les colonnes"])},
  "When detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quand"])},
  "When appeared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparu le"])},
  "Add user in RP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un participant"])},
  "Download Ishikawa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger Ishikawa"])},
  "Download report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger rapport"])},
  "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport"])},
  "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "Progress of RP n° ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progression RP n° "])},
  "Pareto diagram of probable causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagramme de Pareto des causes probables"])},
  "Total number of causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de causes"])},
  "Reactivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réactivité"])},
  "First securing action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Première action de sécurisation"])},
  "No securing action in place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune action de sécurisation en place"])},
  "Started date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de lancement"])},
  "Late Planned actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions planifiées en retard"])},
  "Late completed actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions réalisées avec retard"])},
  "Identified actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions identifiées"])},
  "Total number of actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total d'actions"])},
  "Actions distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répartition des actions"])},
  "Median delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retard médian"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours"])},
  "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
  "Percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourcentage"])},
  "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unité"])},
  "days short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["j"])},
  "hours short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h"])},
  "minutes short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
  "RP Pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numérotation des résolutions"])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "Action details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail d'une action"])},
  "Securing action details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail d'une action de sécurisation"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "Confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
  "Event details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'événement"])},
  "Confirm deletion of an event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de suppression d'un évènement"])},
  "Do you really want to delete this engagement ? \nIt will close the details window previously opened.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer cet engagement ? \nCela fermera la fenêtre de détails précédemment ouverte"])},
  "Confirmation of archiving unfinished engagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de l'archivage d'un engagement non terminé"])},
  "Would you like to archive an engagement that hasn't been completed ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous archiver un engagement non terminé ?"])},
  "Identified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiées"])},
  "Planned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planifiées"])},
  "Delayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En retard"])},
  "Undefined category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie non-définie"])},
  "Done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fait"])},
  "In progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
  "To do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À faire"])},
  "Securisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurisation"])},
  "Capitalisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitalisation"])},
  "Completes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminées"])},
  "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse postale"])},
  "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "Securing date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de sécurisation"])},
  "Detected Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de détection"])},
  "Securing date tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'action de sécurisation est en place, le client est protégé\n- La date de sécurisation est postérieure à la date de détection du problème\n- Au moins une étude d'impact lié à l'action avant de pouvoir mettre une date de sécurisation"])},
  "Identified Singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiée"])},
  "Planned Singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planifiée"])},
  "Securing actions total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total d'actions de sécurisation"])},
  "Causes total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des causes"])},
  "Actions total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total d'actions"])},
  "Planned actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions planifiées"])},
  "Completed actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions réalisées"])},
  "Effective actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions efficaces"])},
  "Documents total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des documents liés à la résolution de problème enregistrés sur le serveur"])},
  "Documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
  "Upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléverser"])},
  "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
  "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
  "Edit a file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier un fichier"])},
  "File deletion confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de suppression d'un fichier"])},
  "Are you sure you want to delete this file ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer ce fichier ?"])},
  "Create a new problem resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle résolution de problème"])},
  "Add a securing action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une action de sécurisation"])},
  "Add an action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une action"])},
  "Add a cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une cause"])},
  "Validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
  "Tools-6W tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QQOQCP est une méthode qui permet de formaliser la description d'un problème. Dans notre cas le 'P' dédié au 'Pourquoi' sera traité dans les causes probables."])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditer"])},
  "Are you sure you want to close this problem resolution ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir clore la résolution de ce problème ?"])},
  "Problem resolution closing confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de clôture d'une résolution des problèmes"])},
  "New problem resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle résolution de problème"])},
  "Notes total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des notes liées à la résolution de problème"])},
  "Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
  "Add a note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une note"])},
  "Selected action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action sélectionnée"])},
  "Probable causes list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des causes probables"])},
  "Link action to probable causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lier l'action aux causes probables"])},
  "Linked causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causes liées"])},
  "Unlinked causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causes non liées"])},
  "All causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les causes"])},
  "Sort & filter options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options de tri et de filtrage"])},
  "All (Default)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous (Par défaut)"])},
  "Register date descending (Default)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'enregistrement décroissante (Par défaut)"])},
  "Register date ascending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'enregistrement croissante"])},
  "Last activity descending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière activité décroissant"])},
  "Last activity ascending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière activité croissant"])},
  "Title A-Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre A-Z"])},
  "Title Z-A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre Z-A"])},
  "Description A-Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description A-Z"])},
  "Description Z-A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description Z-A"])},
  "With a pilot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec un pilote"])},
  "Without a pilot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans pilote"])},
  "With a securing date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec date de sécurisation"])},
  "Without a securing date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans date de sécurisation"])},
  "Ranking ascending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classement croissant"])},
  "Ranking descending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classement décroissant"])},
  "Sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier"])},
  "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer"])},
  "Efficients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficaces"])},
  "Action efficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action efficace"])},
  "Efficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficace"])},
  "Type list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste type"])},
  "Add a capitalising action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une action de capitalisation"])},
  "Link capitalising action to efficient actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lier une action de capitalisation à des actions efficaces"])},
  "Capitalising actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions de capitalisation"])},
  "Capitalising actions total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total d'actions de capitalisation"])},
  "Planned capitalising actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions de capitalisation planifiées"])},
  "Completed capitalising actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions de capitalisation complétées"])},
  "Selected capitalising action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action de capitalisation sélectionnée"])},
  "Efficient actions list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des actions efficaces"])},
  "All actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les actions"])},
  "Linked actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions liées"])},
  "Unlinked actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions non liées"])},
  "Capitalising actions list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste d’actions de capitalisation"])},
  "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
  "Add a template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un modèle"])},
  "Template title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre du modèle"])},
  "Late days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours de retard"])},
  "Capitalising action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action de capitalisation"])},
  "Action completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action terminée"])},
  "Recurrent problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problème récurrent"])},
  "from x to y days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["de ", _interpolate(_list(0)), " à ", _interpolate(_list(1)), " jours"])},
  "more than or equal to x days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["≥ ", _interpolate(_list(0)), " jours"])},
  "RP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résolution de problèmes"])},
  "Open duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée d'ouverture"])},
  "Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
  "Pareto Action Title Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pareto des actions par service"])},
  "Duration RP Title Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagramme des résolutions ouvertes"])},
  "Late Action Title Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagramme des actions en retard"])},
  "Counters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compteurs"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jour"])},
  "Description PLACEHOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description..."])},
  "Title PLACEHOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre..."])},
  "Add a pilot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un pilote"])},
  "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["autres"])},
  "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "Open date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'ouverture"])},
  "Last RP visited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière résolution"])},
  "A.M.D.E.C legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A.M.D.E.C : Analyse des Modes de Défaillance de leurs Effets et de leur Criticité"])},
  "Max number of files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de fichier max"])},
  "Warning too much file selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention :\nVous avez sélectionné trop de fichiers"])},
  "Warning file to big": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Attention : Le fichier '", _interpolate(_named("fileName")), "' est trop gros"])},
  "RP Number pattern year 4 digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permet d'ajouter l'année de création de la résolution de problèmes sur 4 digits."])},
  "RP Number pattern year 2 digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permet d'ajouter l'année de création de la résolution de problèmes sur 2 digits."])},
  "RP Number pattern month 2 digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permet d'ajouter le mois de création de la résolution de problèmes sur 2 digits."])},
  "RP Number pattern month 1 or 2 digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permet d'ajouter le mois de création de la résolution de problèmes sur au 1 ou 2 digits."])},
  "RP Number pattern day 2 digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permet d'ajouter le jour de création de la résolution de problèmes sur 2 digits."])},
  "RP Number pattern day 1 or 2 digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permet d'ajouter le jour de création de la résolution de problèmes sur au 1 ou 2 digits."])},
  "RP Number pattern x digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permet de convertir le numéro de la résolution de problèmes avec le nombre de # digits."])},
  "RP Number pattern example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour la 12ième résolution, ouverte le 05 Juillet 2023, le numérotation."])},
  "Example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemple"])},
  "The resolution doesn't exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La résolution n'existe pas"])},
  "_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au"])},
  "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["période"])},
  "Late actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions en retard"])},
  "Recurrence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récurrence"])},
  "Duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée médiane"])},
  "Resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résolution"])},
  "Actions and Capitalising actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions et actions de capitalisations"])},
  "There are x late actions out of a total of y": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Il y a ", _interpolate(_list(0)), " actions en retard sur ", _interpolate(_list(1)), " au total"])},
  "There are x recurring RP out of a total of y": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Il y a ", _interpolate(_list(0)), " résolutions récurrentes sur ", _interpolate(_list(1)), " au total"])},
  "Median time securring action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Le temps médian de sécurisation est de ", _interpolate(_list(0))])},
  "Median time progress RP": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Le temps médian d'une résolution est de ", _interpolate(_list(0))])},
  "Recurrent resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résolution récurrente"])},
  "This week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette semaine"])},
  "This month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce mois-ci"])},
  "This year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette année"])},
  "Tools-Context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contexte"])},
  "Tools-Context tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Contexte va apporter des détails supplémentaires."])},
  "Use context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser le contexte"])},
  "Tools-Context question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contexte et explications détaillées utiles à l’analyse."])},
  "Services list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des services"])},
  "Add a service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un service"])},
  "Service name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du service"])},
  "name PLACEHOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom..."])},
  "Add a user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un participant"])},
  "Users_bis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
  "Users list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des utilisateurs"])},
  "Firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "Lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "Management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
  "Modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "Confirmation of service deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de la suppression du service"])},
  "Are you sure you want to delete the x service ?": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer le service \"", _interpolate(_list(0)), "\" ?"])},
  "Users of this service will not be on any service": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Les utilisateurs de ce service (", _interpolate(_list(0)), ") se retrouveront sur aucun service"])},
  "Last year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'année dernière"])},
  "Last month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mois dernier"])},
  "Last week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La semaine dernière"])},
  "Impact Studies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étude d'impact"])},
  "Answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse"])},
  "Question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
  "Link secruring action to impact studies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lier l'action de sécurisation aux études d'impact"])},
  "List of impact studies with a response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des études d'impact avec une réponse"])},
  "All impact studies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les études d'impact"])},
  "Linked impact studies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Études d'impact liées"])},
  "Unlinked impact studies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Études d'impact non liées"])},
  "No impact study is associated with the RP.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune étude d'impact n'est associé à la RP."])},
  "{0} impact study with no response": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " étude d'impact à renseigner"])},
  "{0} impact studies with no response": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " études d'impact à renseigner"])},
  "Search bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barre de recherche"])},
  "Tools-IsIsNot-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est/N'est pas"])},
  "Tools-IsIsNot tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifier les éléments concernés ou pas"])},
  "Is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est"])},
  "Is not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N'est pas"])},
  "Is tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est concerné par le problème"])},
  "Is not tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N'est pas concerné par le problème"])},
  "Empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vide"])},
  "Label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé"])},
  "Is/Is not tool default list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des critères par défaut de l'outil Est/N'est pas"])},
  "Is/Is not tool default list tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des critères créés par défaut dans une résolution de problème pour l'outil Est/N'est pas"])},
  "Section label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé du critère"])},
  "Add a section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un critère"])},
  "Tool 5 why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 Pourquoi"])},
  "Selected node": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noeud sélectionné"])},
  "Add a Why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un Pourquoi"])},
  "Delete Why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le Pourquoi"])},
  "Create as causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer en causes"])},
  "Integrate in causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrer en causes"])},
  "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
  "Selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionné"])},
  "Unselected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désélectionné"])},
  "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
  "Lists of why to create to causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listes des pourquoi à créer en causes"])},
  "Why PLACEHOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé du Pourquoi..."])},
  "Tool 5 why tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le 5 pourquoi est une méthode d'identification des causes profondes d'un problème sous la forme d'un arbre des causes"])},
  "Why deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de suppression d'un Pourquoi"])},
  "Why deletion BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La suppression du Pourquoi sélectionné entraînera la suppression de ses Pourquoi enfants.<br>Confirmer vous donc la suppression du Pourquoi sélectionné ?"])},
  "Modify a section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier un critère"])},
  "Is field description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du champ Est"])},
  "Is not field description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du champ N'est pas"])},
  "Particularity field description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du champ Particularité"])},
  "Change field description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du champ Changement"])},
  "Particularity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particularité"])},
  "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement"])},
  "Cause details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails d'une cause"])},
  "Fishbone icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icône d'arêtes de poisson"])},
  "Company logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo de l'entreprise"])},
  "No logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun logo"])},
  "Select a logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un logo"])},
  "Logo deletion HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de suppression du logo"])},
  "Logo deletion BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer le logo ?"])},
  "Valid extensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensions valides : .png, .jpeg, .jpg"])},
  "Choose file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir fichier"])},
  "Reset tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remettre par défaut"])},
  "ishikawa_7m HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remise par défaut des 7M de l'Ishikawa"])},
  "ishikawa_7m BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment remettre par défaut les libellés des 7M du diagramme Ishikawa ?<br>Cela annulera toutes modifications sur ces derniers."])},
  "capitalisation_template HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remise par défaut des actions de capitalisation"])},
  "capitalisation_template BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment remettre par défaut les actions de capitalisation ?<br>Cela annulera toutes modifications sur ces dernières et supprimera toutes celles ajoutées."])},
  "is_is_not_template HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remise par défaut des critères de base du Est/N'est pas"])},
  "is_is_not_template BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment remettre par défaut les critères de base du Est/N'est pas ?<br>Cela annulera toutes modifications sur ces derniers et supprimera tous ceux ajoutés."])},
  "Not linked causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causes non liées"])},
  "Is Is not row deletion HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de suppression d'une ligne"])},
  "Is Is not row deletion BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer cette ligne ?<br>Cela supprimera la ligne et tout le contenu présent sur cette dernière."])},
  "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
  "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagnol"])},
  "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguais"])},
  "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allemand"])},
  "Is/Is not criterion deletion confirmation HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression du critère"])},
  "Is/Is not criterion deletion confirmation BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer ce critère et toutes les descriptions qui lui sont associées ?"])},
  "Capitalisation template deletion confirmation HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression de l'action de capitalisation"])},
  "Capitalisation template deletion confirmation BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer cette action de capitalisation ?"])},
  "Ishikawa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ishikawa"])},
  "Report customization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisation du rapport"])},
  "Sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sections"])},
  "Tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outils"])},
  "Documents allowed tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuls les documents avec les extensions valides seront affichés."])},
  "Allowed extensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensions valides :"])},
  "Client name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom client"])},
  "Enter a client name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir un nom client"])},
  "Client number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro client"])},
  "Enter a client number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir un numéro client"])},
  "RP Number pattern site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permet d'ajouter le site de la résolution de problèmes"])},
  "RP Number pattern service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permet d'ajouter le service du pilote de la résolution de problèmes"])},
  "Copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright © PRODEO"])},
  "Congratulations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Félicitations"])},
  "Go to home page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à l'accueil"])},
  "Stay here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rester ici"])},
  "Tool-priority-matrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrice de priorisation"])},
  "Tool-priority-matrix tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outil qui permet selon des critères choisis de définir un classement des causes probables."])},
  "Causes weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids des causes"])},
  "Modify a criterion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier un critère"])},
  "Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids"])},
  "Ascending weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids ascendant"])},
  "Criterion name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du critère"])},
  "Criterion description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du critère"])},
  "Add a criterion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un critère"])},
  "Classify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classer"])},
  "Criteria templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles de critères"])},
  "Templates list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des modèles"])},
  "Priority matrix criteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critères de la matrice de priorité"])},
  "Priority matrix criteria tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des critères type proposés dans l'outil de matrice de priorité"])},
  "priority_matrix_template HEADER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remise par défaut de la matrice de priorité"])},
  "priority_matrix_template BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment remettre par défaut les critères de base de la matrice de priorité ?<br>Cela annulera toutes modifications sur ces derniers et supprimera tous ceux ajoutés."])},
  "PR progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progression de la RP"])},
  "Pareto priority matrix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pareto de la matrice de priorité"])},
  "Pareto of probable causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pareto des causes probables"])},
  "Indicators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicateurs"])},
  "Create an RP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une résolution de problème"])},
  "Today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
  "Participants of the problem solving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants de la résolution de problème"])},
  "Archived actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions archivées"])},
  "Ongoing actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions en cours"])},
  "My settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes paramètres"])},
  "Standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
  "Strip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bandelette"])},
  "Restricted content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu restreint"])},
  "Global settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres globaux"])},
  "Select all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous sélectionner"])},
  "Unselect all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous désélectionner"])},
  "Error message 403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["403 : Vous ne disposez pas des autorisations nécessaires"])},
  "Error message 404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 : Route API introuvable"])},
  "Error message 405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["405 : Méthode HTTP non autorisée"])},
  "Error message 406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["406 : Vous n'êtes pas authentifié"])},
  "Error message 407": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["407 : Votre compte utilisateur est bloqué"])},
  "Error message 408": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["408 : Votre licence est expirée"])},
  "Error message 409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["409 : Votre token d'authentification n'est pas valide"])},
  "RP progressions chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progression des résolutions"])},
  "D1 tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir le problème"])},
  "D2 tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construire l'équipe"])},
  "D3 tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implémenter les actions de sécurisation"])},
  "D4 tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifier les causes racines du problème"])},
  "D5 tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifier et planifier les solutions"])},
  "D6 tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implémenter et qualifier les solutions"])},
  "D7 tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre en place les actions de capitalisation"])},
  "D8 tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les actions de capitalisation sont réalisées\nFélicitations à l'équipe"])},
  "Tool-qrqc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRQC"])},
  "Tool-qrqc tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'outils QRQC (Quick Response Quality Control) permet de trouver méthodiquement les causes de non détéction et de création du problème"])},
  "Effect of factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effet du facteur"])},
  "Effect of factor PLACEHOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effet du facteur..."])},
  "Add a QRQC Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un point de controle"])},
  "Add a QRQC Factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un effet du facteur"])},
  "Control point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point de controle"])},
  "Control point PLACEHOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point de controle..."])},
  "Standard PLACEHOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard..."])},
  "OK part measurement PLACEHOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesure de la pièce OK..."])},
  "NOK part measurement PLACEHOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesure de la pièce NOK..."])},
  "OK part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièce OK"])},
  "NOK part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièce NOK"])},
  "Measurement or specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesure ou spécification"])},
  "Measurement with tolerance ( X +/- ) or technical specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesure avec tolérance ( X +/- ) ou spécification technique"])},
  "Measurement or technical specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesure ou spécification technique"])},
  "Observation versus standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observation versus standard"])},
  "Does the measurement or technical specification observed on the OK part comply with the standard ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mesure ou la spécification technique observée sur la pièce OK est-elle conforme au standard ?"])},
  "The standard presents the effect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le standard présente l'effet"])},
  "Does a part that conforms to the standard show the effect of the factor ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une pièce conforme au standard présente-elle l'effet du facteur ?"])},
  "No validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non valider"])},
  "Non-detection factors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facteurs de non detection"])},
  "Find out why the problem was not detected ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher les raisons pour lesquelles le problème n'a pas été détecté ?"])},
  "Creation factors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facteurs de création"])},
  "Search for reasons why the problem was generated ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher les raisons pour lesquelles le problème a été généré ?"])},
  "Are you sure you want to remove the effect ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir supprimer l'effet ?"])},
  "There are {0} control point(s) associated with this effect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Il y a ", _interpolate(_list(0)), " point(s) de contrôle associé(s) à cet effet"])},
  "Control points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points de contrôle"])},
  "Validate as a probable cause of the effect \"{0}\"": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Valider ce point de controller comme une cause probable de l'effet \"", _interpolate(_list(0)), "\""])},
  "Integrate retained causes into the resolution process.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrer les causes retenu dans le processus de résolution."])},
  "{0} potentially leads to the effect of {1} \"{2}\"": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["\"", _interpolate(_list(0)), "\" entraine potentiellement l'effet du ", _interpolate(_list(1)), " \"", _interpolate(_list(2)), "\""])},
  "Integrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrer"])}
}